<template>
  <div>
    <v-data-table
        :loading="loading"
        :loading-text="$t('commons.loading')"
        :headers="headers"
        :items="codes"
        :items-per-page="pagination.per_page"
        :hide-default-footer="true"
        class="elevation-0 clickable border-all"
        disable-pagination
    >
      <template v-slot:item.valid_from="{ item }">
        {{$parseDate(item.valid_from)}}
      </template>

      <template v-slot:item.valid_to="{ item }">
        {{$parseDate(item.valid_to)}}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
            small
            class="mr-2"
            @click="edit(item)"
        >
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>

    <v-divider></v-divider>

    <div class="text-center py-3">
      <v-pagination
          v-model="pagination.current"
          :length="pagination.total"
          :total-visible="5"
          @input="getCodes()"
          :disabled="isLoading"
      ></v-pagination>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex"
import {EventBus} from "@/event-bus"

export default {
  name: "List",
  data() {
    return {
      userFilter: '',
      filterMenu: false,
      filters: {
        exact: {},
        like: {}
      },
      headers: [
        {text: this.$t('commons.fields.id'), value: 'id', sortable: false, width: '5%'},
        {text: this.$t('campaign_codes.fields.code'), value: 'code', sortable: false},
        {text: this.$t('campaign_codes.fields.points'), value: 'points', sortable: false, width: '10%'},
        {text: this.$t('campaign_codes.fields.valid_from'), value: 'valid_from', sortable: false, width: "12%"},
        {text: this.$t('campaign_codes.fields.valid_to'), value: 'valid_to', sortable: false, width: "12%"},
        {text: this.$t('campaign_codes.fields.counter'), value: 'counter', sortable: false, width: "12%"},
        {text: '', value: 'actions', sortable: false, width: '4%', align: 'center'}
      ]
    }
  },

  mounted() {
    this.getCodes()
  },

  methods: {
    getCodes() {
      this.$store.dispatch('campaignCode/fetchCodes', this.filters)
    },
    edit(code) {
      console.log('edit option ', code)
      this.isEditing = true
      EventBus.$emit('edit-campaign-code', code)
      this.dialog = true
    },

  },

  computed: {
    ...mapGetters({
      isLoading: 'campaignCode/loadingState',
      codes: 'campaignCode/getCodes',
      pagination: 'campaignCode/getPagination'
    }),

    loading: {
      get() {
        return this.isLoading
      },
      set(v) {
        this.$store.dispatch('venchiOption/setLoadingStatus', v)
      }
    },
  }
}
</script>

<style scoped>

</style>
