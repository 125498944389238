<template>
    <v-dialog
        v-model="dialog"
        max-width="700px"
        persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            class="mb-2"
            v-bind="attrs"
            v-on="on"
            :disabled="isLoading"
        >
          {{$t('commons.actions.new')}}
        </v-btn>
      </template>

      <v-card>
        <v-card-title>
          {{ formTitle }}
        </v-card-title>

        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
          <v-container>

            <v-row>
              <v-col>
                <v-text-field
                    v-model="code.code"
                    :rules="requiredRules"
                    :label="$t('campaign_codes.fields.code')"
                    required
                    outlined
                    dense
                    :disabled="isEditing"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                    v-model="code.points"
                    :rules="requiredRules"
                    :label="$t('campaign_codes.fields.points')"
                    required
                    outlined
                    dense
                    type="number"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <datepicker
                    id="start"
                    :date.sync="code.valid_from"
                    :label="$t('campaign_codes.fields.valid_from')"
                    :max="code.valid_to"
                ></datepicker>
              </v-col>
              <v-col>
                <datepicker
                    id="start"
                    :date.sync="code.valid_to"
                    :label="$t('campaign_codes.fields.valid_to')"
                    :min="code.valid_from"
                ></datepicker>
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-row>
              <v-col>
                <dialog-buttons
                    :valid="valid"
                    :is-loading="isLoading"
                    @save="storeUser"
                    @close="close"
                ></dialog-buttons>
              </v-col>
            </v-row>

          </v-container>
        </v-form>
      </v-card>

    </v-dialog>
</template>

<script>

  import clonedeep from 'lodash.clonedeep'
  import DialogButtons from "@/components/form/DialogButtons";
  import Datepicker from "@/components/form/Datepicker";
  import {mapGetters} from "vuex";
  import {EventBus} from "@/event-bus";
  import { validationRules } from "@/mixins/validationRules";
  import moment from "moment";

  export default {
    name: "Form",

    components: {DialogButtons, Datepicker},

    mixins: [validationRules],

    data: () => ({
      code: {},
      defaultCode: {
        code: '',
        points: 0,
        valid_from: moment().toISOString().substr(0, 10),
        valid_to: moment().toISOString().substr(0, 10)
      },
      isEditing: false,
      dialog: false,
      sending: false,
      valid: false,
    }),

    mounted() {

      let self = this

      this.code = clonedeep(this.defaultCode)

      EventBus.$on('edit-campaign-code', function (code) {
              self.code = clonedeep(code)
        self.isEditing = true
        self.dialog = true
      })
    },

    computed: {
      ...mapGetters({
        isLoading: 'campaignCode/loadingState'
      }),
      formTitle () {
        return (this.isEditing === true) ? this.$t('campaign_codes.edit.title') : this.$t('campaign_codes.create.title')
      }
    },

    methods: {
      async storeUser() {
        const valid = await this.$refs.form.validate()

        delete this.code.counter

        if (valid) {
          if (!this.isEditing) {
            await this.$store.dispatch('campaignCode/store', this.code)
                .then(resp => {
                  console.log('then close dialog resp ', resp)
                  this.close()
                })
                .catch(err => {
                  console.log('create error err ', err)
                })
          } else {
            await this.$store.dispatch('campaignCode/update', this.code)
                .then(resp => {
                  console.log('then close dialog resp ', resp)
                  this.close()
                })
                .catch(err => {
                  console.log('create error err ', err)
                })
          }
        }
      },

      close () {
        this.dialog = false
        this.valid = true
        this.$refs.form.reset()
        this.$nextTick(() => {
          this.isEditing = false
          this.code = clonedeep(this.defaultCode)
        })
      },
    }
  }
</script>

<style scoped>
.bordered {
  border: 1px solid #dddddd;
}

</style>