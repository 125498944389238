<template>
  <v-sheet class="pa-5">

    <code-form></code-form>
    <code-list></code-list>

  </v-sheet>

</template>

<script>

import campaignCode from "@/store/modules/campaignCode";
import List from "@/components/campaign-codes/List";
import Form from "@/components/campaign-codes/Form";

export default {
  components: {
    'code-list': List,
    'code-form': Form
  },
  data() {
    return {}
  },

  beforeMount() {
    if (!this.$store.hasModule('campaignCode')) {
      this.$store.registerModule('campaignCode', campaignCode)
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
